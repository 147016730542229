// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/web/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/web/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-blog-archive-js": () => import("/opt/build/repo/web/src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-pages-archive-old-hopefully-js": () => import("/opt/build/repo/web/src/pages/archive-old-hopefully.js" /* webpackChunkName: "component---src-pages-archive-old-hopefully-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

